.kp-button-list {
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    margin-bottom: var(--hds-spacing-24-32); 

    &.stretched { 
        justify-content: space-between;
        flex-direction: column;

        button,
        a {
            flex-grow:1;
        }
    }

    &.normal {
        flex-wrap:wrap;
    }
}

@media (width > 720px) {
    .kp-button-list.stretched {
        flex-direction: row;
    }

}